<template>
  <div>

    <el-row>
      <el-col :span="4">
        <h4>Mockup-Studie</h4>
      </el-col>
      <el-col :span="20" class="text-right">
          <el-button type="primary" @click="onSaveDiagnosis({resetForm: false})">{{dialogSaveButtonTitle}}</el-button>
      </el-col>
    </el-row>
    <hr/>

    [Studien-Formular]

    <h5 class="mt-4">Zugewiesene Diagnosen</h5>
    [Tabelle Diagnosen mit Möglichkeit Diagnosen hinzuzufügen]

    <h5 class="mt-4">Zugewiesene Patienten</h5>

    [ggf. Suchfunktion/Filter]

    <el-row class="mt-1">
      <el-col :span="12">

        <el-table
            style="width: 100%"
            stripe>
          <el-table-column
              prop="date"
              label="Name"
              sortable>
            <template #default="scope">
              <span v-if="scope.row.date">{{ $filters.germanDate(scope.row.date) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="diagnostic_method_id"
              label="Vorname">
            <template #default="scope">
              <span v-if="scope.row.meta && scope.row.meta.diagnosticmethod">{{ scope.row.meta.diagnosticmethod.title }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="evaluation"
              label="Geburtsdatum">
            <template #default="scope">
              <span v-if="scope.row.evaluation">{{ scope.row.evaluation }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="70">
            <template #default="scope">
              <div class="text-right">

                <el-popconfirm
                    :title="'Sie möchten die Zuweisung zum Befund vom ' + $filters.germanDate(scope.row.date) + ' löschen?'"
                    confirmButtonText='Ja, löschen'
                    confirmButtonType="danger"
                    cancelButtonText='Nein'
                    iconColor="red"
                    @confirm="onDeleteFinding(scope.row)">
                  <template #reference>
                    <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
                  </template>
                </el-popconfirm>

              </div>
            </template>
          </el-table-column>
        </el-table>

      </el-col>
      <el-col :span="11" :offset="1">
        <div class="text-right">
          <el-button class="w-175px" type="primary" size="mini" icon="el-icon-circle-plus">Eintrag hinzufügen</el-button>
        </div>
        <br/>
        [Anzeige Protokolleinträge wenn ein Patient aus der Liste links angeklickt wurde]
      </el-col>
    </el-row>

    <el-row class="mt-4">
      <el-col :span="12">&nbsp;
        <el-popconfirm
            v-if="currentDiagnosis.id > 0"
            :title="'Sie möchten diese Diagnose vom ' + $filters.germanDate(currentDiagnosis.date) + ' löschen?'"
            confirmButtonText='Ja, löschen'
            confirmButtonType="danger"
            cancelButtonText='Nein'
            iconColor="red"
            @confirm="onDeleteDiagnosis">
          <template #reference>
            <el-button type="danger" icon="el-icon-delete">Diagnose löschen</el-button>
          </template>
        </el-popconfirm>
      </el-col>
      <el-col :span="12" class="text-right">
        <el-button type="primary" @click="onSaveDiagnosis({resetForm: false})">{{dialogSaveButtonTitle}}</el-button>      </el-col>
    </el-row>


 </div>
</template>

<script>

export default {
  name: "StudyDetail",
  props: [],
  components: {},
  data() {
    return {
      newDiagnosisTemplate: {
        date: new Date(),
        findings: [],
        meta: {}
      },
      currentDiagnosis: {},
      selectedFinding: null,
      selectableFindings: [],
      editMode: true
    }
  },
  mounted: async function () {


  },
  computed: {
    dialogTitle() {
      return (this.editMode ? 'Studie' : 'Studie hinzufügen')
    },
    dialogSaveButtonTitle() {
      return (this.editMode ? 'Studie speichern' : 'Studie hinzufügen')
    },
  },
  methods: {
    async onSaveDiagnosis() {
    }
  }
}
</script>
<style scoped>
</style>
